$(document).on('turbolinks:load', function() {
  $('.js-submit-nearest-form-on-change').on('change', function () {
    $(this).closest('form').trigger('submit');
  });

  $('.js-submit-closest-form-on-change').on('change', function () {
    console.log('You used .js-submit-closest-form-on-change when you meant to use .js-submit-nearest-form-on-change!');
  });
});

